define("ember-router-scroll/index", ["exports", "ember-app-scheduler"], function (_exports, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ATTEMPTS = 0;
  const MAX_ATTEMPTS = 100; // rAF runs every 16ms ideally, so 60x a second

  let requestId;
  let callbackRequestId;
  let idleRequestId;

  class CounterPool {
    constructor() {
      this._counter = 0;
      this.onFinishedPromise = null;
      this.onFinishedCallback = null;
      this.flush();
    }

    get counter() {
      return this._counter;
    }

    set counter(value) {
      // put a cap so flush queue doesn't take too many paint cycles
      this._counter = Math.min(value, 2);
    }

    flush() {
      if (this.counter === 0 && this.onFinishedPromise && this.onFinishedPromise.then) {
        // when we are done, attach a then callback and update scroll position
        this.onFinishedPromise.then(() => {
          this.onFinishedCallback && this.onFinishedCallback();
        });
      }

      idleRequestId = window.requestAnimationFrame(() => {
        this.decrement();
        this.flush();
      });
    }

    decrement() {
      this.counter = this.counter - 1;
    }

    destroy() {
      window.cancelAnimationFrame(idleRequestId);
      this.counter = 0;
      this.onFinishedPromise = null;
      this.onFinishedCallback = null;
    }

  }
  /**
   * By default, we start checking to see if the document height is >= the last known `y` position
   * we want to scroll to.  This is important for content heavy pages that might try to scrollTo
   * before the content has painted
   *
   * @method tryScrollRecursively
   * @param {Function} fn
   * @param {Object} scrollHash
   * @void
   */


  function tryScrollRecursively(fn, scrollHash) {
    const body = document.body;
    const html = document.documentElement; // read DOM outside of rAF

    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    callbackRequestId = window.requestAnimationFrame(() => {
      // write DOM (scrollTo causes reflow)
      if (documentHeight >= scrollHash.y || ATTEMPTS >= MAX_ATTEMPTS) {
        ATTEMPTS = 0;
        fn.call(null, scrollHash.x, scrollHash.y);
      } else {
        ATTEMPTS++;
        tryScrollRecursively(fn, scrollHash);
      }
    });
  } // to prevent scheduleOnce calling multiple times, give it the same ref to this function


  const CALLBACK = function CALLBACK(transition) {
    this.updateScrollPosition(transition);
  };

  let EmberRouterScroll = (_dec = Ember.inject.service('router-scroll'), _dec2 = Ember.computed, (_class = class EmberRouterScroll extends Ember.Router {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "service", _descriptor, this);

      _defineProperty(this, "idlePool", void 0);
    }

    get isFastBoot() {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }

    init() {
      super.init(...arguments);
      (0, _emberAppScheduler.setupRouter)(this);
      this.on('routeWillChange', () => {
        this._routeWillChange();
      });
      this.on('routeDidChange', transition => {
        this._routeDidChange(transition);
      });
    }

    destroy() {
      (0, _emberAppScheduler.reset)();

      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }

      if (callbackRequestId) {
        window.cancelAnimationFrame(callbackRequestId);
      }

      super.destroy(...arguments);
    }
    /**
     * Updates the scroll position
     * it will be a single transition
     * @method updateScrollPosition
     * @param {transition|transition[]} transition If before Ember 3.6, this will be an array of transitions, otherwise
     */


    updateScrollPosition(transition) {
      if (this.idlePool) {
        this.idlePool.destroy();
        this.idlePool = null;
      }

      const url = Ember.get(this, 'currentURL');
      const hashElement = url ? document.getElementById(url.split('#').pop()) : null;

      if (Ember.get(this, 'service.isFirstLoad')) {
        Ember.get(this, 'service').unsetFirstLoad();
      }

      let scrollPosition;

      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = {
          x: hashElement.offsetLeft,
          y: hashElement.offsetTop
        };
      } else {
        scrollPosition = Ember.get(this, 'service.position');
      }

      let preserveScrollPosition = (Ember.get(transition, 'router.currentRouteInfos') || []).some(routeInfo => Ember.get(routeInfo, 'route.controller.preserveScrollPosition')); // If `preserveScrollPosition` was not set on the controller, attempt fallback to `preserveScrollPosition` which was set on the router service.

      if (!preserveScrollPosition) {
        preserveScrollPosition = Ember.get(this, 'service.preserveScrollPosition');
      }

      if (!preserveScrollPosition) {
        const scrollElement = Ember.get(this, 'service.scrollElement');
        const targetElement = Ember.get(this, 'service.targetElement');

        if (targetElement || 'window' === scrollElement) {
          tryScrollRecursively(window.scrollTo, scrollPosition);
        } else if ('#' === scrollElement.charAt(0)) {
          const element = document.getElementById(scrollElement.substring(1));

          if (element) {
            element.scrollLeft = scrollPosition.x;
            element.scrollTop = scrollPosition.y;
          }
        }
      }

      this.trigger('didScroll', transition);
    }

    _routeWillChange() {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      Ember.get(this, 'service').update();
    }

    _routeDidChange(transition) {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      const scrollWhenIdle = Ember.get(this, 'service.scrollWhenIdle');
      const scrollWhenAfterRender = Ember.get(this, 'service.scrollWhenAfterRender');

      if (!scrollWhenIdle && !scrollWhenAfterRender) {
        // out of the option, this happens on the tightest schedule
        Ember.run.scheduleOnce('render', this, CALLBACK, transition);
      } else if (scrollWhenAfterRender && !scrollWhenIdle) {
        // out of the option, this happens on the tightest schedule
        Ember.run.scheduleOnce('afterRender', this, CALLBACK, transition);
      } else {
        if (!this.idlePool) {
          this.idlePool = new CounterPool();
        } // increments happen all in one batch (before processing flush queue) and happens indeterminately
        // e.g. 4, 6, 10 times this could be called


        this.idlePool.counter = this.idlePool.counter + 1;
        this.idlePool.onFinishedPromise = (0, _emberAppScheduler.whenRouteIdle)();
        this.idlePool.onFinishedCallback = this.updateScrollPosition.bind(this, transition);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFastBoot", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isFastBoot"), _class.prototype)), _class));
  var _default = EmberRouterScroll;
  _exports.default = _default;
});