define("true-myth/unit", ["exports", "true-myth/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Unit = void 0;

  /**
    The `Unit` type exists for the cases where you want a type-safe equivalent of
    `undefined` or `null`. It's a concrete instance, which won't blow up on you,
    and you can safely use it with e.g. [`Result`](../modules/_result_.html)
    without being concerned that you'll accidentally introduce `null` or
    `undefined` back into your application.
   */
  const Unit = new _utils._Brand('unit');
  _exports.Unit = Unit;
  var _default = Unit;
  _exports.default = _default;
});