define("ember-svg-jar/inlined/recents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M30 14.972c.015 8.264-6.725 15.022-14.99 15.028a14.937 14.937 0 01-9.424-3.321 1.45 1.45 0 01-.111-2.154l.681-.681a1.454 1.454 0 011.93-.12A11.084 11.084 0 0015 26.129 11.123 11.123 0 0026.129 15c0-6.151-4.978-11.129-11.129-11.129a11.09 11.09 0 00-7.625 3.02l3.07 3.07c.61.61.178 1.652-.685 1.652H.968A.968.968 0 010 10.645V1.852C0 .99 1.042.56 1.652 1.168l2.986 2.986A14.949 14.949 0 0115 0c8.275 0 14.985 6.7 15 14.972zm-10.942 4.765l.594-.764a1.452 1.452 0 00-.255-2.037l-2.462-1.915V8.71c0-.802-.65-1.452-1.451-1.452h-.968c-.802 0-1.452.65-1.452 1.452v8.204l3.957 3.077a1.452 1.452 0 002.037-.254z\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});