define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 6.514L3.86 17.43c-.09.083-.205.121-.304.19v10.475c0 .5.398.906.889.906h7.11c.492 0 .89-.406.89-.906v-7.25c0-.5.398-.906.889-.906h5.333c.49 0 .889.405.889.906v7.25c0 .5.398.906.889.906h7.11c.492 0 .89-.406.89-.906V17.62c-.095-.066-.207-.103-.293-.181L16 6.514zm15.705 6.87L17.486.582a2.182 2.182 0 00-2.969 0L.295 13.383a.917.917 0 00-.067 1.28l1.19 1.35a.879.879 0 001.256.069L15.41 4.623a.876.876 0 011.177 0l12.74 11.457a.879.879 0 001.255-.069l1.189-1.35a.919.919 0 00-.068-1.279z\"/>",
    "attrs": {
      "width": "32",
      "height": "29",
      "viewBox": "0 0 32 29",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});