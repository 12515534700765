define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.996 15.504a1.5 1.5 0 001.501 1.501h9.006a1.5 1.5 0 001.5-1.5V4.996H.997v10.507zM9.502 7.5a.5.5 0 111 0v7.004a.5.5 0 11-1 0V7.5zM6.5 7.5a.5.5 0 011 0v7.004a.5.5 0 11-1 0V7.5zm-3.002 0a.5.5 0 011 0v7.004a.5.5 0 11-1 0V7.5zm10.006-5.503H9.752l-.294-.585a.75.75 0 00-.673-.416H5.211a.742.742 0 00-.669.416l-.294.585H.496a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13.008a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "viewBox": "-2 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});