define('ember-object-update/index', ['exports', 'ember-object-update/utils/update'], function (exports, _update) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _update.default;
    }
  });
});