define("ember-svg-jar/inlined/r-select-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"nonzero\"><path d=\"M0 7l3 3 3-3zM0 3l3-3 3 3z\"/></g>",
    "attrs": {
      "viewBox": "0 0 6 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});