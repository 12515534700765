define("ember-svg-jar/inlined/r-facebook-logo-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M.06.04H1024v1017.74H.06z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M1024 512C1024 229.23 794.77 0 512 0S0 229.23 0 512c0 255.554 187.231 467.37 432 505.78V660H302V512h130V399.2C432 270.88 508.438 200 625.39 200 681.407 200 740 210 740 210v126h-64.562C611.835 336 592 375.467 592 415.957V512h142l-22.7 148H592v357.78c244.769-38.41 432-250.226 432-505.78\" fill=\"#FFFFFE\" mask=\"url(#b)\"/></g>",
    "attrs": {
      "width": "1024",
      "height": "1024",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});