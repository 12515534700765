define("a11y-announcer/components/route-announcer", ["exports", "a11y-announcer/templates/components/route-announcer"], function (_exports, _routeAnnouncer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    announcer: Ember.inject.service('announcer'),
    layout: _routeAnnouncer.default,
    classNames: ['screen-reader'],
    attributeBindings: ['ariaLive:aria-live'],
    ariaLive: Ember.computed('tone', function () {
      return this.get('announcer').getWithDefault('announceTone', 'polite');
    }),
    tone: Ember.computed.alias('announcer.announceTone'),
    message: Ember.computed.alias('announcer.announceMessage')
  });

  _exports.default = _default;
});