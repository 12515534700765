define("ember-svg-jar/inlined/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.777 7.53l-5.362 5.25c-.478.469-1.308.14-1.308-.531v-3H3.766A.756.756 0 013 8.5v-3c0-.415.342-.749.766-.749h4.34v-3c0-.668.827-1 1.31-.53l5.361 5.249a.745.745 0 010 1.062zM5 12.626v-1.25c0-.206-.14-.375-.313-.375H2.5c-.46 0-.833-.447-.833-1V4c0-.553.372-1 .833-1h2.188C4.859 3 5 2.831 5 2.625v-1.25C5 1.169 4.86 1 4.687 1H2.5C1.12 1 0 2.344 0 4v6c0 1.656 1.12 3 2.5 3h2.188c.171 0 .312-.169.312-.375z\"/>",
    "attrs": {
      "width": "15",
      "height": "12",
      "viewBox": "0 0 15 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});