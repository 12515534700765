define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-ca", {
    "mwc": {
      "accountHeading": {
        "favorites": "Favourite Orders"
      },
      "basket": {
        "disabledMessage": "This favourite is no longer available due to a menu change. Please access our menu to see our products."
      },
      "errors": {
        "favoriteVendorLoginRequiredMessage": "An account is needed to favourite this location."
      },
      "favorites": {
        "delete": {
          "confirmText": "Are you sure you would like to delete this favourite? You cannot undo this action.",
          "confirmTitle": "Delete Favourite"
        },
        "emptyText": "You don't have any favourites",
        "reorder": {
          "confirmBasketDifferentVendorText": "Do you want to clear your existing cart at {basketVendor} and reorder your favourite order from {favoriteVendor}?",
          "confirmBasketText": "Do you want to clear your existing cart and reorder your favourite order from {favoriteVendor}?"
        }
      },
      "postCheckout": {
        "favoriteButton": "Save Order as Favourite",
        "favoriteMessage": "Must enter a name for your favourite"
      },
      "saveFavorite": {
        "accountButtonFavoriteOrders": "Favourite Orders",
        "createAccountMessage": "Make an account so you can view your order history, quickly reorder favourites, save address and payment info, and more!",
        "createAccountPrompt": "You'll need an account to save this order as a favourite! An account will also enable you to view your order history, save addresses and payment details, and more.",
        "favoriteName": "Favourite Name",
        "saveForFutureOrders": "Be sure to favourite this order for next time!",
        "saveLabel": "Save Favourite",
        "saveOrder": "Save Order as Favourite",
        "saveOrderAsFavorite": "Save Order as Favourite",
        "savingLabel": "Saving Favourite",
        "setFavorite": "Set as favourite",
        "unsetFavorite": "Unfavourite"
      },
      "vendorCard": {
        "enterAddressMessage": "Please enter your address to find locations near you! Be sure to include city and province for the most accurate results, or search with just a postal code.",
        "favoriteStoreText": "This is a favourite store"
      }
    }
  }], ["en-us", {
    "mwc": {
      "accountHeading": {
        "accountSettings": "Account Settings",
        "changePassword": "Change Password",
        "createAccount": "Create Account",
        "favorites": "Favorite Orders",
        "forgotPassword": "Forgot Password",
        "recentOrders": "Recent Orders",
        "signIn": "Sign In"
      },
      "addressForm": {
        "addAddressDetailsLabel": "Add Address Details",
        "addressDetailsLabel": "Address Details",
        "addressModeAriaLabel": "New or existing address",
        "buildingLabel": "Apt, Floor, Suite, Building, Company",
        "cityLabel": "City",
        "streetAddressLabel": "Street Address",
        "useExistingLabel": "Existing Addresses",
        "useExistingPlaceholder": "Select an existing address",
        "zipCodeLabel": "Postal Code"
      },
      "basket": {
        "disabledMessage": "This favorite is no longer available due to a menu change. Please access our menu to see our products."
      },
      "basketTransferModal": {
        "cancelButton": "Cancel",
        "confirmTitle": "Your cart will be changed",
        "emptyConfirmTitle": "Your cart will be cleared",
        "emptyWarning": "does not offer any of the items from your cart.",
        "searchButton": "Search",
        "searchLabel": "Address",
        "successMessage": "Location changed to {name}",
        "successMessageShort": "Location changed.",
        "switchButton": "Switch",
        "title": "Change Location",
        "transferButton": "Switch to this Location",
        "warningPartialBody": "However, {name} does not offer these products: {products}. They have been removed from your cart.",
        "warningPartialTitle": "Location changed successfully!"
      },
      "calendar": {
        "lastDay": "[{titleCase, select, true {Yesterday} other {yesterday}}]",
        "lastWeek": "[{titleCase, select, true {Last} other {last}}] dddd",
        "nextDay": "[{titleCase, select, true {Tomorrow} other {tomorrow}}]",
        "nextWeek": "dddd",
        "sameDay": "[{titleCase, select, true {Today} other {today}}]",
        "sameElse": "MMM Do YYYY",
        "timeWanted": "{date} at {time, time, short}",
        "timeWantedAtStore": "{time} at the store",
        "timeWantedAtStoreHtml": "<strong>{time}</strong> at the store",
        "timeWantedHtml": "<strong>{date}</strong> at <strong>{time, time, short}</strong>"
      },
      "carousel": {
        "count": "({visible} of {total})",
        "itemListAriaLabel": "{title}",
        "scrollLeftButton": "Scroll left",
        "scrollRightButton": "Scroll right",
        "viewAllAriaLabel": "View all {title}",
        "viewAllLink": "View All"
      },
      "cart": {
        "addMoreItemsButton": "Add More Items",
        "checkoutButton": "Checkout",
        "checkoutButtonDisabled": "Add {remainingAmount} to Continue",
        "closeButtonLabel": "Close Cart",
        "emptyButton": "Start Your Order",
        "emptyText": "Your cart is empty",
        "handoffMinimum": "There is a {minimum} {mode} minimum.",
        "headerText": "Your Order",
        "immediateLabel": "{description} {leadTime, plural, =0 {} =1 {(1 min est.)} other {({leadTime} mins est.)}}",
        "invalidCoupon": {
          "error": "{error} Would you like to remove the coupon?",
          "keep": "Keep",
          "remove": "Remove"
        },
        "itemAddedMessage": "Item added",
        "removeProductButton": "Remove {product}",
        "showMoreItemsLabel": "Show {count} More {count, plural, =1 {Item} other {Items}}",
        "singleUpsellGroupTitle": "May We Suggest",
        "upsellScrollLeftLabel": "Scroll upsells left",
        "upsellScrollRightLabel": "Scroll upsells right",
        "upsellSectionLabel": "May we suggest additional items",
        "vendorReturnPreposition": "from"
      },
      "challenge": {
        "title": "Are you a human?"
      },
      "changePassword": {
        "cancelButton": "Cancel",
        "confirmPasswordLabel": "Confirm Password",
        "currentPasswordLabel": "Current Password",
        "errors": {
          "minLength": "Password must be at least 8 characters long.",
          "passwordsMatch": "New passwords do not match."
        },
        "newPasswordLabel": "New Password",
        "submitButton": "Submit",
        "successMessage": "Password changed successfully! Please log in again with your new password.",
        "successTitle": "Password Changed"
      },
      "checkout": {
        "addCouponSuccessMessage": "Coupon code {code} has been applied.",
        "addCouponSuccessTitle": "Success!",
        "addReceivingCustomerAriaLabel": "Ordering for someone else? Add them to the order.",
        "addReceivingCustomerHelpText": "Ordering for someone else? Add them to the order.",
        "additionalDetailsHeader": "Additional Details",
        "applyRewardButton": "Apply",
        "cancelRemoveDiscountButton": "No",
        "compCardDefaultDescription": "Gift Card",
        "confirmRemoveButton": "Remove",
        "confirmRemoveDiscountButton": "Yes",
        "confirmRemoveText": "Are you sure you would like to remove the payment method? Any changes will be lost.",
        "confirmRemoveTitle": "Remove Payment Method",
        "contactlessDeliveryHelp": "You can use this to ask for contactless delivery",
        "contactlessDeliveryText": "I want contactless delivery",
        "couponLabel": "Coupon Code",
        "customFeeLabel": "Estimated Taxes and Fees",
        "deliveryFeeLabel": "Delivery Charge",
        "deliveryHelpText": "Delivery charges reflect current availability and may change.",
        "deliveryInstructionsHeader": "Delivery Instructions",
        "deliveryInstructionsPlaceholder": "e.g. Contactless Delivery, Cross Street, Buzzer #",
        "driverTip": "100% goes to the driver",
        "editMembershipButton": "Edit",
        "editMethodButton": "Edit",
        "enterAddress": "Enter Address, City, or Postal Code",
        "errors": {
          "requiredCustomField": "{label} is required.",
          "requiredDeliveryInstructions": "Delivery Instructions are required."
        },
        "giftCardBalance": "{amount} available",
        "header": "Checkout",
        "immediateLabel": "{description} {leadTime, plural, =0 {} =1 {(1 min est.)} other {({leadTime} mins est.)}}",
        "informationSectionHeading": "Order Information",
        "lessChoicesLabel": "-hide choices",
        "linkMembershipButton": "Link",
        "linkMembershipSuccessMessage": "Your {provider} account is linked.",
        "membershipsLabel": "Saved {count, plural, =1 {Card} other {Cards}} on File",
        "messageDefaultText": "Review address & order details for accuracy.",
        "moreChoicesLabel": "+{count} more {count, plural, =1 {choice} other {choices}}",
        "orderDeliveryAddressLabel": "To:",
        "orderSummaryHeader": "Order Summary",
        "orderTimeLabel": "When:",
        "orderTypeHeader": "Order Type",
        "orderTypeLabel": "Type:",
        "orderingFromHeader": "Ordering From",
        "otherLabel": "Other",
        "paymentSectionHeading": "Payment",
        "phoneNumberExplanation": "Contact number is required only in case of issues regarding your order (e.g. items out of stock).",
        "placeOrderButton": "Place Order",
        "postalCode": "Postal Code",
        "removeCouponButton": "Remove",
        "removeCouponText": "Only 1 coupon or reward can be redeemed per order. Do you want to remove the coupon and use the rewards?",
        "removeCouponTitle": "Remove Coupon?",
        "removeMethodButton": "Remove",
        "removeReceivingCustomerAriaLabel": "Is this order for you? Remove the guest from the order.",
        "removeReceivingCustomerHelpText": "Is this order for you? Remove the guest from the order.",
        "removeRewardButton": "Remove",
        "removeRewardDefault": "Are you sure you want to remove the {discountType} {discountLabel} from your order?",
        "removeRewardText": "Only 1 reward can be redeemed per order. Do you want to replace the current reward?",
        "removeRewardTitle": "Remove Reward?",
        "rewardAlreadyApplied": "Are you sure you want to redeem a coupon? You can't apply the coupon and a reward at the same time so applying this coupon will remove your {discountLabel} reward.",
        "rewardExpirationText": "Expires {exp}",
        "rewardsAvailableLabel": "{count, plural, =0 {No Rewards Available} =1 {Available Reward} other {Available Rewards}}",
        "schemesLabel": "Add a New Payment Method",
        "smsOptInLabel": "By checking this box, you consent to receive text messages regarding this order. Text STOP to opt-out. Message and data rates may apply.",
        "splitAmountInputLabel": "Split Amount",
        "splitMembershipButton": "Split",
        "splitRemainingLabel": "Remaining Balance",
        "splitSchemesLabel": "Add a New Split Payment Method",
        "splitTotalLabel": "Total to Split",
        "staffTip": "100% goes to the restaurant staff",
        "subtotalLabel": "Subtotal",
        "tipAmountLabel": "Tip Amount",
        "tipDisclaimer": "If you would like to add a tip, select a different payment method or tip in-person.",
        "tipHeader": "Tip",
        "tipLabel": "Tip",
        "tipMethodHeader": "Tip Method",
        "totalLabel": "Total",
        "totalsDiscountLabel": "Discount: {label}",
        "useMembershipButton": "Use"
      },
      "checkoutGuestToggle": {
        "toAccountButton": "Sign In or Create an Account",
        "toAccountPrompt": "Want to pay faster next time?",
        "toGuestButton": "Proceed as Guest",
        "toGuestPrompt": "Don't have an account?"
      },
      "confirmModal": {
        "cancelLabel": "Cancel"
      },
      "contactInfo": {
        "fullName": "{firstName} {lastName}",
        "guestHeader": "Order's Guest Contact Info",
        "header": "Contact Info"
      },
      "creditCardModal": {
        "addHeader": "Add Credit Card",
        "addLabel": "Add Card",
        "cardNumberLabel": "Card Number",
        "cityLabel": "City",
        "clearLabel": "Clear",
        "confirmDeleteButton": "Delete",
        "confirmDeleteText": "Are you sure you would like to delete the card on file? You cannot undo this action.",
        "confirmDeleteTitle": "Delete Credit Card",
        "countryLabel": "Country",
        "countryPlaceholder": "Select a country",
        "cvvLabel": "CVV",
        "deleteLabel": "Delete",
        "editHeader": "Edit Credit Card",
        "editLabel": "Save",
        "errors": {
          "cardNumber": "Credit card number was invalid or missing.",
          "cardType": "{cardType, select, VISA {Sorry! Visa is not accepted at this location.} AMEX {Sorry! American Express is not accepted at this location.} Discover {Sorry! Discover is not accepted at this location.} Mastercard {Sorry! Mastercard is not accepted at this location.} other {Sorry! This card type is not accepted at this location.} }",
          "cvv": "Card security code was invalid or missing.",
          "expDate": "Card expiration was invalid or missing.",
          "zipCode": "Card zip / postal code was invalid or missing."
        },
        "expDateLabel": "Exp. Date",
        "saveCardLabel": "Save card to account?",
        "stateLabel": "State",
        "statePlaceholder": "Select a state",
        "streetAddress2Label": "Suite / Apt",
        "streetAddressLabel": "Billing Street Address",
        "zipCodeLabel": "Zip / Postal Code"
      },
      "dictionary": {
        "loading": "loading..."
      },
      "dispatchStatus": {
        "detailsButton": "Details",
        "providerAttribution": "Your order will be delivered by {deliveryService}. For order issues, please contact the store directly."
      },
      "dispatchTracker": {
        "activeUpdatesAriaLabel": "Receiving Automatic Status Updates",
        "activeUpdatesIconLabel": "Live Order",
        "deliveredLabel": "Delivered",
        "driver": "Driver Info",
        "driverName": "Driver Name",
        "estimate": {
          "completedLabel": "Delivery",
          "completedTime": "Complete!",
          "estimatedArrival": "Estimated Arrival In",
          "estimatedDelivery": "Estimated Delivery",
          "estimatedDropoffRange": "{startTime} - {endTime}",
          "estimatedDropoffTime": "{time, plural, =1 {'< 1 minute away'} other {{time} minutes} }",
          "returnedLabel": "Delivery",
          "returnedTime": "Returned",
          "returningLabel": "Delivery",
          "returningTime": "Returning"
        },
        "heading": "Order Tracker",
        "inactiveUpdatesAriaLabel": "Not Receiving Automatic Status Updates",
        "noDriverCoordinates": "Driver Coordinates Unavailable",
        "orderReceivedLabel": "Order Received",
        "outForDeliveryLabel": "Out for Delivery",
        "pickupInProgressLabel": "Pickup in Progress",
        "returnLabel": "Return"
      },
      "editContactInfo": {
        "cancelLabel": "Cancel",
        "contactNumberLabel": "Phone Number",
        "defaultModalTitle": "Edit Contact Info",
        "emailAddressLabel": "Email",
        "firstNameLabel": "First Name",
        "guestModalTitle": "Add Guest",
        "lastNameLabel": "Last Name",
        "saveLabel": "Save"
      },
      "editableSection": {
        "buttonLabel": "Edit"
      },
      "errors": {
        "favoriteVendorLoginRequiredMessage": "An account is needed to favorite this location.",
        "loadAddresses": "Sorry! We weren't able to load your saved delivery addresses.",
        "loadMembership": "Sorry! We weren't able to load your loyalty memberships.",
        "loginFailed": "The email or password you entered was incorrect",
        "loginRequiredAction": "Sign In or Create an Account",
        "loginRequiredMessage": "You must be logged in to do that.",
        "loginRequiredTitle": "Please Log In"
      },
      "expandableText": {
        "collapseButton": "Show {isCollapsed, select, true {More} other {Less}}"
      },
      "favorites": {
        "delete": {
          "confirmButton": "Delete",
          "confirmText": "Are you sure you would like to delete this favorite? You cannot undo this action.",
          "confirmTitle": "Delete Favorite"
        },
        "deleteButton": "Delete",
        "disabledTag": "Not Available",
        "emptyButton": "Start a New Order",
        "emptyText": "You don't have any favorites",
        "reorder": {
          "confirmBasketDifferentVendorText": "Do you want to clear your existing cart at {basketVendor} and reorder your favorite order from {favoriteVendor}?",
          "confirmBasketText": "Do you want to clear your existing cart and reorder your favorite order from {favoriteVendor}?",
          "confirmButton": "Order Again",
          "confirmDifferentVendorText": "This will create a cart from {favoriteVendor}.",
          "confirmTitle": "Order Again"
        },
        "reorderButton": "Order Again",
        "viewDetailsButton": "View Details",
        "viewMenuButton": "View Menu"
      },
      "feedbackBanner": {
        "dismissLabel": "Dismiss",
        "successTitle": "Success!"
      },
      "footer": {
        "accessibilityText": "Accessibility",
        "androidBadgeAltText": "Get it on Google Play",
        "appVersion": "Version",
        "closeText": "Close",
        "contactUsText": "Contact Us",
        "devMenuText": "Dev Menu",
        "feedbackText": "Feedback",
        "iosBadgeAltText": "Download on the App Store",
        "locationsText": "Locations",
        "oloAttributionText": "Online Ordering by",
        "oloDotComText": "Olo.com",
        "openSourceLicenses": "Open Source Licenses",
        "parentSiteText": "Visit {name}",
        "privacyPolicyText": "Privacy Policy",
        "reviewInAppleStoreText": "Review in App Store",
        "reviewInGoogleStoreText": "Review in Play Store",
        "userAgreementText": "User Agreement",
        "visitDesktopText": "Visit Desktop"
      },
      "forgotPassword": {
        "cancelButton": "Cancel",
        "continueButton": "Continue",
        "emailAddressLabel": "Email Address",
        "errors": {
          "email": "Email address must be set and valid"
        },
        "instructions1": "Enter your email address and we will send you an email.",
        "instructions2": "If your email address matches an account, it will contain a link to reset your password. If your email address does not match an account, the email will let you know.",
        "instructions3": "For your added security, any credit card details stored with your account will be cleared. You will be asked to re-enter your card details on your next purchase.",
        "successMessage": "Thank you. You'll receive an email shortly to reset your password. If you do not see the mail please be sure to check your junk mail folder.",
        "successTitle": "Email Sent"
      },
      "formField": {
        "characterCount": "{remaining} {remaining, plural, =1 {character} other {characters}} remaining"
      },
      "geo": {
        "locationUnavailableMessage": "Sorry, your location is not currently available.",
        "lookUpFailedTitle": "Location Lookup Failed",
        "timeoutMessage": "Sorry, the location request timed out!",
        "turnOnLocationMessage": "Location Services is currently disabled. Please enable it to use your current location."
      },
      "geolocationPrompt": {
        "bodyText": "Search for nearby restaurants using your current location?",
        "cancelButton": "Cancel",
        "loadingText": "Getting your location",
        "searchButton": "Search",
        "title": "Search Nearby"
      },
      "giftCardModal": {
        "addHeader": "Add Gift Card",
        "addLabel": "Add Card",
        "cardNumberLabel": "Card Number",
        "clearLabel": "Clear",
        "confirmDeleteButton": "Delete",
        "confirmDeleteText": "Are you sure you would like to delete the card on file? You cannot undo this action.",
        "confirmDeleteTitle": "Delete Gift Card",
        "deleteLabel": "Delete",
        "editHeader": "Edit Gift Card",
        "editLabel": "Save",
        "errors": {
          "cardNumber": "Gift card number must be between {min} and {max} characters long.",
          "pinFixed": "{pin} must be {min} {min, plural, =1 {character} other {characters}} long.",
          "pinMax": "{pin} can't be more than {max} characters long.",
          "pinRange": "{pin} must be between {min} and {max} characters long."
        },
        "pinLabel": "PIN",
        "saveCardLabel": "Save card to account?"
      },
      "header": {
        "accountButtonOffersLabel": "Offers",
        "accountButtonRecentOrdersLabel": "Recent Orders",
        "accountButtonSettingsLabel": "Account Settings",
        "accountButtonSignInLabel": "Sign In",
        "accountButtonSignOutLabel": "Sign Out",
        "accountButtonYourAccountLabel": "Your Account",
        "backAltText": "Back",
        "cartAriaLabel": "{count, plural, =0 {Empty} =1 {{count} item in} other {{count} items in}} cart",
        "cartLabel": "{count, plural, =1 {Item} other {Items}}",
        "cartLoadingText": "Loading new cart",
        "homeAltText": "Visit Our Home Page",
        "menuAltText": "Menu"
      },
      "iconAriaLabel": {
        "rAmex": "American Express",
        "rCash": "Cash",
        "rCreditCard": "Credit Card",
        "rDiscover": "Discover",
        "rGiftCard": "Gift Card",
        "rInfo": "Info",
        "rLevelup": "LevelUp",
        "rMastercard": "Mastercard",
        "rVisa": "Visa"
      },
      "linkRewards": {
        "linkAccountButton": "Link",
        "linkAccountLabel": "Have an account with {name}?",
        "linkMembershipSuccessButton": "View Rewards",
        "linkMembershipSuccessMessage": "You have successfully linked your {provider} account",
        "linkRewardsButton": "Link Rewards",
        "rewardsAvailableButton": "Rewards Available",
        "viewRewardsButton": "View Rewards",
        "viewRewardsLabel": "You have an account with {name}"
      },
      "locations": {
        "countryCodes": {
          "ca": "Canada",
          "us": "United States"
        },
        "crumbRootLabel": "All Locations",
        "heading": "All Locations",
        "headingPreposition": "in",
        "mapButton": "View Map",
        "noResults": "No locations found in this region.",
        "noResultsReturnLink": "Go back."
      },
      "marketingOptIn": {
        "optInLabelDefault": "Send me emails about future deals and features",
        "optInProviderDefault": "",
        "optOutDescriptionDefault": ""
      },
      "menu": {
        "disclaimersHeader": "Disclaimers",
        "recentOrderAriaLabel": "See more details for recent order from {timePlaced}",
        "recentOrdersTitle": "Recent Orders",
        "reorderAriaLabel": "Reorder recent order from {timePlaced}",
        "reorderButton": "Reorder Now",
        "searchPlaceholderText": "Search the menu"
      },
      "menuFilter": {
        "fullMenuLabel": "View Full Menu"
      },
      "myAccount": {
        "changePasswordLink": "Change Password",
        "confirmDeleteButton": "Delete",
        "confirmDeleteText": "Are you sure you would like to delete the card on file? You cannot undo this action.",
        "confirmDeleteTitle": "Delete Card",
        "deleteButton": "Delete",
        "externalAccountText": "You logged in with an external account. You need to update your profile details using that account. Changes will take effect on your next login.",
        "giftCardBalance": "{amount} available",
        "header": "Account",
        "loyaltyHeader": "Loyalty Memberships",
        "makeDefaultButton": "Make Default",
        "savedCardsHeader": "Saved Cards",
        "unlinkButton": "Unlink"
      },
      "nativeUpdateModal": {
        "message": "This version of the application is no longer supported. Please upgrade to the latest version.",
        "positiveButtonAndroid": "Go to Play Store",
        "positiveButtoniOS": "Go to App Store",
        "title": "Update Available"
      },
      "notifications": {
        "added": "{quantity, plural, =0 {1} other {{quantity}}} {quantity, plural, =0 {item} =1 {item} other {items}} added to cart.",
        "close": "close",
        "removed": "{quantity, plural, =0 {1} other {{quantity}}} {quantity, plural, =0 {item} =1 {item} other {items}} removed from cart.",
        "updated": "{quantity, plural, =0 {1} other {{quantity}}} {quantity, plural, =0 {item} =1 {item} other {items}} updated in cart."
      },
      "oloPay": {
        "cardErrorModal": {
          "button": "Refresh",
          "content": "It looks like there was an issue loading this form. Please try refreshing the page or select a different payment method.",
          "title": "Unable to Add Credit Card"
        },
        "creditCardElements": {
          "card": "Card Number",
          "cvc": "CVV",
          "expiry": "Exp. Date",
          "zip": "Zip / Postal Code"
        },
        "errorMessages": {
          "card": "Sorry! This card is not accepted at this location.",
          "cvc": "The card security code is invalid or missing.",
          "zip": "The card / postal code is invalid or missing."
        },
        "placeholders": {
          "cvc": "",
          "expiry": "MM/YY",
          "number": ""
        }
      },
      "openSourceLicenses": {
        "description": "This software may contain portions of the following libraries, subject to the licenses below",
        "header": "Open Source Licenses"
      },
      "optOutGuide": {
        "linkText": "Online-Tracking Opt Out Guide"
      },
      "orderCriteria": {
        "changeLocationText": "Change Location?",
        "deliveryPreposition": "to",
        "editAriaLabel": "Edit Order Criteria",
        "editOrderTitle": "Edit Order",
        "editSearchTitle": "Edit Search",
        "handoffModeLabel": "Handoff Mode",
        "handoffModePlaceholder": "Select a handoff mode",
        "orderTimeLabel": "Order Time",
        "orderTimeSectionHeader": "Order Time",
        "orderTypeSectionHeader": "Order Type",
        "proximityPreposition": "near",
        "timePreposition": "at",
        "timeWantedModeAdvance": "Scheduled",
        "timeWantedModeImmediate": "ASAP",
        "timeWantedModeManualFire": "Check-in",
        "uninitializedButtonText": "Start a new order",
        "unknownTimeText": "Please select a time",
        "vendorPreposition": "from"
      },
      "orderSearchResults": {
        "arrivalButton": "I'm Here",
        "checkInButton": "Check-in",
        "orderStatus": "Status: {status}{description, select, NONE {} other { - {description}}}",
        "orderTotal": "Total: {total}",
        "reorderButton": "Order Again",
        "viewDetailsButton": "View Details"
      },
      "outage": {
        "downDescription": "We’re working to fix the problem and will let you know when you can get back to ordering!",
        "downStatus": "Current Status: Down",
        "errorHeader": "It looks like there is an issue with our systems.",
        "resolvedHeader": "Our systems are back online.",
        "upDescription": "Thank you for your patience. You may now get back to ordering!",
        "upStatus": "Current Status: Online"
      },
      "postCheckout": {
        "arrivalButton": "I'm Here",
        "arrivalInstructions": "Your order has been confirmed. When you arrive, please let us know you're here so the store knows to bring your order.",
        "checkInButton": "Check-in",
        "createAccountLabel": "Create Account",
        "favoriteButton": "Save Order as Favorite",
        "favoriteMessage": "Must enter a name for your favorite",
        "feedbackLabel": "Give Feedback",
        "feedbackPrompt": "How was your ordering experience? Let us know so we can keep doing what works — and improve what doesn't!",
        "instructionsHeader": "{label} Instructions",
        "parkingLocationHeading": "Check-in",
        "parkingLocationLabel": "Parking Location",
        "parkingLocationMessage": "In order to complete check-in, please tell us your parking spot number or where you are located.",
        "parkingLocationPlaceholder": "Change me",
        "postOrderCustomFeeLabel": "Taxes and Fees",
        "reorderButton": "Order Again",
        "shareOrderLabel": "Share Order",
        "successHeading": "Success!",
        "successMessage": "Thanks for letting us know you've arrived! We will bring out your meal shortly.",
        "thankyouHeader": "Thank You!",
        "time": "{time, time, short}",
        "timeWanted": "{date} {time, time, short}"
      },
      "product": {
        "addToCart": "Add to Cart",
        "recipientNameLabel": "Made for",
        "specialInstructionsDescription": "*No price altering substitutions/additions",
        "specialInstructionsLabel": "Special Instructions",
        "updateCart": "Update Cart"
      },
      "productCard": {
        "availabilityText": "Available {availability}",
        "featuredLabel": "Featured",
        "productButtonAriaLabel": "{name} details",
        "quickAddLabel": {
          "default": "Add {product} to cart",
          "success": "Added {product} to cart"
        }
      },
      "productCustomization": {
        "completedText": "Completed",
        "deselect": "Deselect {name}",
        "doneButton": "Done with {name}",
        "editAriaLabel": "Edit {name}",
        "editButton": "Edit",
        "groupText": "{price, select, NONE { {calories, select, NONE {{name}} other {{name} ({calories})} } } other { {calories, select, NONE {{name} ({price})} other {{name} ({price} | {calories})} } } }",
        "helpText": {
          "aggregateLabel": "Select a total of {aggregate}",
          "bothEqualLabel": "Select {max}",
          "bothNotEqualLabel": "Select between {min} and {max}",
          "maxOnlyLabel": "Select up to {max}",
          "minAggregateLabel": "Select a total of at least {min}",
          "minOnlyLabel": "Select at least {min}"
        },
        "nestedOptionGroupListLabel": "More Modifiers",
        "nextDoneButton": "Done",
        "optionGroupListLabel": "Modifiers",
        "previousButton": "Back to {name}",
        "requiredText": "Required",
        "select": "Select {name}",
        "selectOptionLabel": "Select an option",
        "selectOptionPlaceholder": "Select an option",
        "totalProductQuantityLabel": "Quantity:"
      },
      "quantityStepper": {
        "decreaseButtonAriaLabel": "Decrease quantity",
        "increaseButtonAriaLabel": "Increase quantity",
        "quantityLabel": "Quantity"
      },
      "recentOrders": {
        "emptyButton": "Start Your First Order",
        "emptyText": "You don't have any recent orders",
        "reorder": {
          "confirmBasketDifferentVendorText": "Do you want to clear your existing cart at {basketVendor} and reorder your recent order from {orderVendor}?",
          "confirmBasketText": "This will replace your cart with {orderSummary}.",
          "confirmButton": "Order Again",
          "confirmDifferentVendorText": "This will create a cart from {orderVendor}.",
          "confirmTitle": "Order Again"
        }
      },
      "resetPassword": {
        "confirmPasswordLabel": "Confirm Password",
        "errors": {
          "minLength": "Password must be at least 8 characters long.",
          "passwordsMatch": "New passwords do not match."
        },
        "header": "Reset Password",
        "instructions": "Please enter your new password to continue. Please note that any credit card details stored on your account will be cleared for your security.",
        "newPasswordLabel": "New Password",
        "submitButton": "Submit",
        "successMessage": "Password changed successfully! You may now log in again.",
        "successTitle": "Password Changed"
      },
      "rewardCard": {
        "applyButton": "Apply Reward",
        "cancelRemoveDiscountButton": "No",
        "confirmRemoveDiscountButton": "Yes",
        "expirationText": "Expires {exp}",
        "removeButton": "Remove Reward",
        "removeCouponText": "Only 1 coupon or reward can be redeemed per order. Do you want to remove the coupon and use this reward?",
        "removeCouponTitle": "Remove Coupon?",
        "removeRewardText": "Only 1 reward can be redeemed per order. Do you want to replace the current reward?",
        "removeRewardTitle": "Remove Reward?"
      },
      "rewards": {
        "availableRewardsText": "Here are your available rewards.",
        "offersNotAvailableText": "There are no offers at this {channelName} location. Sorry about that!"
      },
      "rewardsSearch": {
        "addressPlaceholder": "Address, City, or Postal Code",
        "nearbyLabel": "Near",
        "searchButton": "Search for Rewards"
      },
      "saveFavorite": {
        "accountButtonFavoriteOrders": "Favorite Orders",
        "createAccountMessage": "Make an account so you can view your order history, quickly reorder favorites, save address and payment info, and more!",
        "createAccountPrompt": "You'll need an account to save this order as a favorite! An account will also enable you to view your order history, save addresses and payment details, and more.",
        "favoriteName": "Favorite Name",
        "saveForFutureOrders": "Be sure to favorite this order for next time!",
        "saveLabel": "Save Favorite",
        "saveOrder": "Save Order as Favorite",
        "saveOrderAsFavorite": "Save Order as Favorite",
        "savingLabel": "Saving Favorite",
        "setFavorite": "Set as favorite",
        "unsetFavorite": "Unfavorite"
      },
      "sideMenu": {
        "accountSettingsLink": "Account Settings",
        "closeButtonLabel": "Close Menu",
        "favoritesLink": "Favorite Orders",
        "guestGreeting": "Hi there!",
        "headingText": "Menu",
        "loggedInGreeting": "Hi,",
        "offersLink": "Offers",
        "orderingHomeLink": "Start a New Order",
        "recentOrdersLink": "Recent Orders",
        "rewardsLink": "Rewards",
        "signInLink": "Sign In / Create Account",
        "signOutLink": "Sign Out"
      },
      "social": {
        "appleLogin": "Sign in with Apple",
        "facebookLogin": "Continue with Facebook",
        "googleLogin": "Sign in with Google"
      },
      "splash": {
        "addressConfirmLabel": "Confirm Address",
        "addressEditorTitle": "Delivery Address",
        "addressLabel": "To",
        "addressPlaceholderDelivery": "Delivery Address",
        "addressPlaceholderNonDelivery": "Address, City, or Postal Code",
        "handoffModeLabel": "Type",
        "handoffModePlaceholder": "Select a type",
        "inputAddress": "Enter Address or Postal Code",
        "locations": "View Locations",
        "nearbyLabel": "Near",
        "notAvailableWarningMessageGeneric": "Online ordering is not available at this time. Please try again later.",
        "notAvailableWarningMessageNoTimes": "Online ordering is no longer available today. Please try again later.",
        "notAvailableWarningTitle": "Warning",
        "savingAddressLabel": "Saving Address",
        "searchButtonLabel": "Search for Locations",
        "timeWantedApplyLabel": "Apply",
        "timeWantedEditorTitle": "Edit Time",
        "timeWantedLabel": "When",
        "useCurrentLocationAriaLabel": "Use Current Location"
      },
      "stickyNav": {
        "moreLabel": "More"
      },
      "typeahead": {
        "placeholderText": "Search",
        "searchLabel": "Search"
      },
      "vendor": {
        "businessHoursUnavailable": "{vendorName} is not open yet and will open {day} at {time}",
        "changeLocationButton": "Change Location",
        "closedForReason": "Closed: {reason}",
        "closedNoReason": "Currently Closed",
        "customerFacingMessageTitle": "Location Message",
        "preCheckWarningTitle": "Warning",
        "proceedToMenuButton": "Proceed Anyway",
        "unavailableMessage": "Would you like to change your location?"
      },
      "vendorCard": {
        "beginOrderText": "Begin Order",
        "continueOrderText": "Continue Order",
        "enterAddressMessage": "Please enter your address to find locations near you! Be sure to include city and state for the most accurate results, or search with just a postal code.",
        "favoriteStoreText": "This is a favorite store",
        "noOnlineOrdering": "This location does not offer online ordering.",
        "notSupported": "Not Available",
        "switchLocationsText": "Switch to this Location",
        "viewMenuText": "View Menu",
        "viewRewardsText": "View Rewards"
      },
      "vendorInfo": {
        "freeDelivery": "$0 Delivery Fee",
        "handoffMinimum": "{amount} {mode} Minimum",
        "mapLink": "View Map",
        "menuLink": "View Menu",
        "scheduleAvailability": {
          "beforeOpening": "Opens at {start}",
          "consecutiveAllDay": "Open through midnight on {day}",
          "consecutiveTime": "Open until {end} on {day}",
          "handoffBeforeOpening": "{mode} starts at {start}",
          "handoffConsecutiveAllDay": "{mode} available through midnight on {day}",
          "handoffConsecutiveTime": "{mode} available until {end} on {day}",
          "handoffNowTodayClosed": "Now closed for {mode}",
          "handoffOpen24/7": "{mode} available 24 hours",
          "handoffTodayClosed": "Closed for {mode}",
          "handoffTodayMultiRange": "{mode} available {range}",
          "handoffTodayOpenAllDay": "{mode} available until midnight",
          "handoffTodayOpenNow": "{mode} available until {end}",
          "handoffTodayOpenNowVerbose": "{mode} available from {start} to {end}",
          "nowTodayClosed": "Now closed today",
          "open24/7": "Open 24 hours",
          "todayClosed": "Closed today",
          "todayMultiRange": "Open {range}",
          "todayOpenAllDay": "Open until midnight",
          "todayOpenNow": "Open {start} to {end}"
        },
        "storeInfoButton": "Store Information",
        "timezoneWarning": "It is currently {time} at {vendor}",
        "vendorDistance": "{distance} miles away"
      },
      "vendorInfoModal": {
        "acceptedCardsLabel": "Accepted Cards",
        "addressLabel": "Address",
        "currentDayFormat": "dddd, MMMM D",
        "minimumLabel": "Minimum: {minimum}",
        "openHoursLabel": "Open Hours for {date}",
        "phoneLabel": "Phone Number",
        "title": "Store Hours and Information",
        "viewMapLink": "View Map"
      }
    }
  }]];
  _exports.default = _default;
});