define("ember-svg-jar/inlined/account-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.005 9.006a4.504 4.504 0 000-9.006 4.504 4.504 0 000 9.006zm4.002 1h-1.722a5.45 5.45 0 01-4.56 0H4.003A4.002 4.002 0 000 14.01v.5c0 .829.672 1.501 1.5 1.501h13.01c.828 0 1.5-.672 1.5-1.5v-.501a4.002 4.002 0 00-4.003-4.003z\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});