define("true-myth/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.curry1 = curry1;
  _exports._Brand = _exports.isVoid = void 0;

  /** @internal */

  /**
   * Check if the value here is an all-consuming monstrosity which will consume
   * everything in its transdimensional rage. A.k.a. `null` or `undefined`.
   *
   * @internal
   */
  const isVoid = value => typeof value === 'undefined' || value === null;
  /** @internal */


  _exports.isVoid = isVoid;

  function curry1(op, item) {
    return item !== undefined ? op(item) : op;
  } // tslint:disable-next-line:class-name

  /** @internal */


  class _Brand {
    constructor(t) {
      this._brand = t;
    }

  }

  _exports._Brand = _Brand;
});