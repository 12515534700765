define('ember-object-update/utils/update', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = update;


  // Usage: update(person, 'name', (name) => name.toUpperCase())
  function update(obj, key, updateFn) {
    let property = Ember.get(obj, key);
    let newValue = updateFn(property);
    Ember.set(obj, key, newValue);
    return newValue;
  }
});