define("true-myth/index", ["exports", "true-myth/maybe", "true-myth/result", "true-myth/unit"], function (_exports, MaybeNamespace, ResultNamespace, UnitNamespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Unit = _exports.Result = _exports.Maybe = void 0;

  /**
    This is just here to re-export [`Maybe`](./_maybe_.html) and
    [`Result`](./_result_.html). It doesn't do anything else.
   */
  const Maybe = MaybeNamespace;
  _exports.Maybe = Maybe;
  const Result = ResultNamespace;
  _exports.Result = Result;
  const Unit = UnitNamespace;
  _exports.Unit = Unit;
});