define("ember-svg-jar/inlined/heart-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M27.088 1.778c-3.21-2.71-7.986-2.223-10.933.79L15 3.744l-1.154-1.178c-2.941-3.012-7.722-3.5-10.933-.79-3.68 3.111-3.873 8.694-.58 12.066L13.67 25.44a1.85 1.85 0 002.655 0l11.337-11.596c3.3-3.372 3.106-8.955-.574-12.065z\"/>",
    "attrs": {
      "width": "30",
      "height": "26",
      "viewBox": "0 0 30 26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});