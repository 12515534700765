define('ember-test-friendly-error-handler/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (label, callback) {
    (false && !(label) && Ember.assert('ember-test-friendly-error-handler requires a label', label));

    if (!false /* DEBUG */) {
      return callback;
    }

    let lastReason;
    return function (reason) {
      // avoid reentrance and infinite async loops
      if (reason === lastReason) {
        lastReason = null;
        return;
      }

      lastReason = reason;

      // only call the callback when squelched
      if (squelchedLabels[label]) {
        return callback(reason);
      }

      // otherwise call the callback, and rethrow
      return Ember.RSVP.resolve(callback(reason)).then(() => {
        throw reason;
      });
    };
  };

  let squelchedLabels;

  let squelchErrorHandlerFor = exports.squelchErrorHandlerFor = null;
  let unsquelchAllErrorHandlers = exports.unsquelchAllErrorHandlers = null;

  if (false) {
    squelchedLabels = Object.create(null);

    exports.squelchErrorHandlerFor = squelchErrorHandlerFor = function squelchErrorHandlerFor(label) {
      squelchedLabels[label] = true;
    };

    exports.unsquelchAllErrorHandlers = unsquelchAllErrorHandlers = function unsquelchAllErrorHandlers() {
      squelchedLabels = Object.create(null);
    };
  }
});