define("ember-svg-jar/inlined/r-visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect fill=\"#FFF\" stroke=\"#EEE\" x=\"70.3\" y=\"83.6\" width=\"75.6\" height=\"48.8\" rx=\"4\" stroke-width=\"3\"/><path d=\"M99.4 98.4l-8.1 19.3H86l-4-15.4c-.2-.9-.5-1.3-1.2-1.7-1.2-.6-3.2-1.3-4.9-1.6l.1-.6h8.5c1.1 0 2.1.7 2.3 2l2.1 11.2 5.2-13.1h5.3zm20.7 13c0-5.1-7-5.4-7-7.6 0-.7.7-1.4 2.1-1.6.7-.1 2.7-.2 4.9.9l.9-4.1c-1.2-.4-2.7-.9-4.7-.9-4.9 0-8.4 2.6-8.4 6.4 0 2.8 2.5 4.3 4.4 5.2 1.9.9 2.6 1.5 2.6 2.4 0 1.3-1.5 1.9-3 1.9-2.5 0-4-.7-5.1-1.2l-.9 4.2c1.2.5 3.3 1 5.5 1 5.2 0 8.6-2.6 8.7-6.6m13 6.3h4.6l-4-19.3h-4.3c-1 0-1.8.6-2.1 1.4l-7.5 17.9h5.2l1-2.9h6.4l.7 2.9zm-5.6-6.8l2.6-7.2 1.5 7.2h-4.1zm-21-12.5l-4.1 19.3h-5l4.1-19.3h5z\" fill=\"#1A1F71\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "70.3 83.6 75.6 48.8"
    }
  };
  _exports.default = _default;
});