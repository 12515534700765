define("ember-modal-dialog/templates/components/basic-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tBMv2yTe",
    "block": "[[[6,[39,0],null,[[\"to\"],[[33,1]]],[[\"default\"],[[[[41,[33,3],[[[1,\"    \"],[10,0],[15,0,[29,[[36,4],\" \",[36,5]]]],[12],[1,\"\\n\"],[41,[33,6],[[[1,\"        \"],[10,0],[15,0,[36,7]],[15,\"onclick\",[28,[37,8],[[30,0],[33,9]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[6,[39,10],null,[[\"class\",\"targetAttachment\",\"target\",\"aria-labelledby\",\"aria-describedby\"],[[33,11],[33,12],[33,13],[33,14],[33,15]]],[[\"default\"],[[[[1,\"        \"],[18,1,null],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[[36,4],\" \",[36,5]]]],[12],[1,\"\\n\"],[41,[33,6],[[[1,\"        \"],[10,0],[15,0,[36,7]],[15,\"onclick\",[28,[37,8],[[30,0],[28,[37,17],[[33,9]],null]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n\"],[6,[39,10],null,[[\"class\",\"targetAttachment\",\"target\",\"aria-labelledby\",\"aria-describedby\"],[[33,11],[33,12],[33,13],[33,14],[33,15]]],[[\"default\"],[[[[1,\"            \"],[18,1,null],[1,\"\\n\"]],[]]]]],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[6,[39,10],null,[[\"class\",\"targetAttachment\",\"target\",\"aria-labelledby\",\"aria-describedby\"],[[33,11],[33,12],[33,13],[33,14],[33,15]]],[[\"default\"],[[[[1,\"          \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[\"&default\"],false,[\"ember-wormhole\",\"destinationElementId\",\"if\",\"isOverlaySibling\",\"wrapperClassNamesString\",\"wrapperClass\",\"hasOverlay\",\"overlayClassNamesString\",\"action\",\"onClickOverlay\",\"ember-modal-dialog-positioned-container\",\"containerClassNamesString\",\"targetAttachment\",\"legacyTarget\",\"aria-labelledby\",\"aria-describedby\",\"yield\",\"ignore-children\"]]",
    "moduleName": "ember-modal-dialog/templates/components/basic-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});