define("ember-svg-jar/inlined/r-near-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 0h24v24H0V0z\" fill=\"none\"/><path d=\"M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});