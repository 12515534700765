define("ember-svg-jar/inlined/r-amex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><rect id=\"a\" width=\"48\" height=\"32\" rx=\"2\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#FFF\" xlink:href=\"#a\"/><g mask=\"url(#b)\" fill-rule=\"nonzero\"><path fill=\"#0A7EC2\" d=\"M48.168-7.693H0v48.342h48.168\"/><path d=\"M10.559 19.547l-.605 1.415h-5.42l4.277-8.968h10.217l1.03 1.98.964-1.98h13.774l1.177 1.231 1.265-1.23h6.396l-4.541 4.473 4.345 4.494h-6.19l-1.385-1.321-1.373 1.32H12.394l-.664-1.414h-1.171zm1.174.095h-1.178 1.178zm13.093-6.367h-2.69l-2.026 4.292-2.177-4.292h-2.69v6.213l-2.78-6.213H9.94l-2.972 6.406h1.819l.602-1.46h3.459l.692 1.46h3.343v-5.214l2.37 5.214h1.563l2.357-5.125v5.125h1.653v-6.406zm11.106 2.076l-1.921-2.076h-7.712v6.406h7.456l2.113-2.101 2.127 2.1h1.985l-3.036-3.24 3.126-3.165h-2.075l-2.063 2.076zm-4.06 3.049h-3.92v-1.41h3.92v-1.28h-3.92v-1.154h3.92v-1.088l2.907 2.907-2.908 2.921V18.4zm-20.715-4.074l1.127 2.6H9.94l1.217-2.6z\" fill=\"#FFF\"/></g><rect stroke=\"#EEE\" x=\".5\" y=\".5\" width=\"47\" height=\"31\" rx=\"2\"/></g>",
    "attrs": {
      "viewBox": "0 0 48 32",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});