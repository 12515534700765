define("ember-svg-jar/inlined/r-apple-logo-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Slice</title><path d=\"M10.13 4.615c.875 0 1.973-.61 2.627-1.424.592-.737 1.023-1.767 1.023-2.797 0-.14-.012-.28-.037-.394-.974.038-2.146.674-2.85 1.526-.554.648-1.06 1.665-1.06 2.708 0 .153.025.305.037.356.062.013.16.025.26.025zM7.044 20c1.197 0 1.727-.826 3.22-.826 1.517 0 1.85.8 3.182.8 1.308 0 2.184-1.245 3.01-2.466.925-1.399 1.308-2.772 1.332-2.835-.086-.026-2.59-1.081-2.59-4.044 0-2.568 1.974-3.725 2.085-3.814-1.308-1.933-3.294-1.983-3.837-1.983-1.468 0-2.664.915-3.417.915-.814 0-1.887-.865-3.157-.865C4.455 4.882 2 6.942 2 10.832c0 2.417.913 4.972 2.035 6.625C4.998 18.856 5.836 20 7.045 20z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});