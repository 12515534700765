define("ember-svg-jar/inlined/r-levelup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-3-4h24v24H-3z\"/><path d=\"M0 9h8v8H0zm9 0h8v8H9zm0-9h8v8H9z\" fill=\"currentColor\"/></g>",
    "attrs": {
      "viewBox": "0 0 17 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});