define("ember-svg-jar/inlined/r-discover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" d=\"M99.9 76.6c0 3.7-3 6.7-6.7 6.7H6.7c-3.7 0-6.7-3-6.7-6.7V23.3c0-3.7 3-6.7 6.7-6.7h86.6c3.7 0 6.7 3 6.7 6.7l-.1 53.3z\"/><path fill=\"#E57E25\" d=\"M93.4 83.2c3.7 0 6.5-2.6 6.5-6.3V57.2s-25.6 18.1-72.8 26h66.3z\"/><path opacity=\".1\" fill=\"#231F20\" d=\"M100 57.2S74.3 75.5 27.1 83.4c56.3 0 72.9-26.2 72.9-26.2z\"/><path fill=\"#231F20\" d=\"M10.7 36.7h-4v13.9h4c2.1 0 3.6-.5 5-1.6 1.6-1.3 2.5-3.3 2.5-5.4-.1-4.1-3.1-6.9-7.5-6.9zm3.1 10.4c-.9.8-2 1.1-3.7 1.1h-.7V39h.7c1.8 0 2.8.3 3.7 1.1s1.5 2.1 1.5 3.5-.5 2.7-1.5 3.5zM19.4 36.7h2.7v13.9h-2.7zM28.8 42c-1.6-.6-2.1-1-2.1-1.8 0-.9.9-1.5 2-1.5.8 0 1.5.3 2.2 1.1l1.4-1.9c-1.2-1-2.6-1.5-4.1-1.5-2.5 0-4.3 1.7-4.3 4 0 1.9.9 2.9 3.4 3.8 1.1.4 1.6.6 1.9.8.5.4.8.9.8 1.4 0 1.1-.9 2-2.1 2-1.3 0-2.3-.6-3-1.9l-1.8 1.7c1.3 1.8 2.8 2.7 4.8 2.7 2.8 0 4.8-1.9 4.8-4.6.1-2.1-.8-3.2-3.9-4.3zM33.6 43.6c0 4.1 3.2 7.3 7.4 7.3 1.2 0 2.2-.2 3.4-.8v-3.2c-1.1 1.1-2.1 1.5-3.3 1.5-2.7 0-4.7-2-4.7-4.8 0-2.7 2-4.8 4.6-4.8 1.3 0 2.3.5 3.4 1.6v-3.2c-1.2-.6-2.2-.9-3.3-.9-4.2 0-7.5 3.3-7.5 7.3zM66 46l-3.7-9.3h-3L65.2 51h1.5l6-14.3h-2.9zM73.9 50.6h7.7v-2.4h-5v-3.7h4.9v-2.4h-4.9V39h5v-2.3h-7.7zM92.4 40.8c0-2.6-1.8-4.1-4.9-4.1h-4v13.9h2.7V45h.4l3.8 5.6h3.3l-4.4-5.9c2-.4 3.1-1.8 3.1-3.9zM87 43.1h-.8v-4.2h.8c1.7 0 2.6.7 2.6 2.1 0 1.3-.9 2.1-2.6 2.1z\"/><circle fill=\"#E57E25\" cx=\"52.7\" cy=\"43.7\" r=\"7.4\"/><rect stroke=\"#EEE\" fill=\"none\" x=\".5\" y=\"17.1\" width=\"99\" height=\"65.8\" rx=\"6\" stroke-width=\"3\"/>",
    "attrs": {
      "viewBox": "0 16.6 100 66.8"
    }
  };
  _exports.default = _default;
});