define("ember-svg-jar/inlined/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"50\" cy=\"50\" r=\"40\" stroke=\"#ddd\" stroke-width=\"10\" fill=\"none\"/><path d=\"M10 50a40 40 0 0140-40\" stroke=\"currentColor\" stroke-width=\"10\" fill=\"none\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});