define("a11y-announcer/services/announcer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    message: 'has loaded',
    announceMessage: null,
    announceTone: 'polite',
    announce: function announce(message, tone) {
      this.setProperties({
        announceMessage: message,
        announceTone: tone
      });
    }
  });

  _exports.default = _default;
});