define("ember-svg-jar/inlined/account-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.5 0C11.413 0 0 11.413 0 25.5S11.413 51 25.5 51 51 39.587 51 25.5 39.587 0 25.5 0zm0 9.871a9.049 9.049 0 110 18.097 9.049 9.049 0 010-18.097zm0 35.37c-6.036 0-11.444-2.734-15.064-7.012 1.934-3.64 5.717-6.148 10.129-6.148.246 0 .493.04.73.113 1.336.432 2.735.71 4.205.71 1.47 0 2.879-.278 4.205-.71.237-.072.484-.113.73-.113 4.412 0 8.195 2.509 10.129 6.148-3.62 4.278-9.028 7.013-15.064 7.013z\"/>",
    "attrs": {
      "width": "51",
      "height": "51",
      "viewBox": "0 0 51 51",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});