define("ember-app-scheduler/helpers/route-idle", ["exports", "ember-app-scheduler/scheduler"], function (_exports, _scheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RouteIdle extends Ember.Helper {
    constructor(...args) {
      super(...args);
      this.isIdle = false;
    }

    compute() {
      if (this.isIdle) {
        return true;
      }

      (0, _scheduler.whenRouteIdle)().then(() => {
        this.isIdle = true;
        this.recompute();
      });
      return false;
    }

  }

  _exports.default = RouteIdle;
});