define("ember-svg-jar/inlined/r-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><circle fill-opacity=\".1\" cx=\"24\" cy=\"24\" r=\"24\"/><path d=\"M24 44C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20-8.954 20-20 20zm3-28l-1-3H14v22h3v-9.059h7.133L25 29h9V16h-7z\"/></g>",
    "attrs": {
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});