define("ember-svg-jar/inlined/times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.697 8l5.128-5.128 1.058-1.057a.4.4 0 000-.566L14.751.118a.4.4 0 00-.565 0L8 6.303 1.815.117a.4.4 0 00-.566 0L.117 1.249a.4.4 0 000 .565L6.303 8 .117 14.186a.4.4 0 000 .565l1.132 1.132a.4.4 0 00.565 0L8 9.697l5.128 5.128 1.058 1.058a.4.4 0 00.565 0l1.132-1.132a.4.4 0 000-.565L9.697 8z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});