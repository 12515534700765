define("ember-svg-jar/inlined/r-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-2-4h24v24H-2z\"/><path d=\"M9 13h2v-1h1c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1H9V6h4V4h-2V3H9v1H8c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H7v2h2v1zm9-13H2C.89 0 .01.89.01 2L0 14c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2zm0 14H2V2h16v12z\" fill=\"currentColor\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});